ion-button {
  margin: 0;
  margin-inline: 0;
  letter-spacing: 0.1px;
  text-transform: none;
  --box-shadow: none;
  font-weight: var(--font-weight-normal);
  height: 40px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: 6px;

  &.button-solid {
    --border-width: 2px;
    --border-style: solid;
    --border-color: var(--ion-color-base, var(--ion-color-primary));
  }

  &.button-small {
    font-size: 0.75rem;
    letter-spacing: normal;
    height: 28px;
  }

  &.button-large {
    font-size: 2rem;
    letter-spacing: 0.1rem;
    height: 56px;
  }

  & ion-spinner[slot='start'] {
    margin-right: 0.625em;
  }

  & ion-spinner[slot='end'] {
    margin-left: 0.625em;
  }

  & ion-spinner[slot='start'],
  & ion-spinner[slot='end'],
  & ion-spinner[slot='icon-only'] {
    height: 1.25em;
    width: 1.25em;
  }
}
