@use '@techniek-team/lyceo-style' as typo;

.mat-mdc-form-field {
  @include typo.typography(typo.tt-body-2());
  --padding-start: 16px;
  --padding-end: 16px;

  .mat-mdc-text-field-wrapper {
    background: transparent;
    padding-inline-start: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));
    padding-inline-end: var(--padding-end);
  }

  .mat-mdc-form-field-focus-overlay {
    background: transparent;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-112%) scale(0.7);
  }
}
