:root {
  color: var(--ion-text-color);
  --tt-icon-size: 24px;
  --ion-font-family: 'Rubik', sans-serif;

  --ion-background-color: var(--lyceo-white-background);
  --ion-background-color-rgb: var(--lyceo-white-background-rgb);
  --ion-text-color: var(--lyceo-dark-text-color);
  --ion-text-color-rgb: var(--lyceo-dark-text-color-rgb);
  --ion-backdrop-color: var(--lyceo-dark-blue);
  --ion-backdrop-color-opacity: 91;
  --ion-overlay-background-color: var(--lyceo-grey-background);
  --ion-border-color: var(--lyceo-dark-blue-90);
  --ion-box-shadow-color: var(--lyceo-dark-blue);
  --ion-tab-bar-background: var(--lyceo-white-background);
  --ion-tab-bar-background-focused: var(--lyceo-white-background);
  --ion-tab-bar-border-color: var(--lyceo-green-95);
  --ion-tab-bar-color: var(--lyceo-dark-blue);
  --ion-tab-bar-color-selected: var(--lyceo-element-text-color-mint);
  --ion-toolbar-background: var(--lyceo-grey-background);
  --ion-toolbar-border-color: var(--lyceo-dark-blue-90);
  --ion-toolbar-color: var(--lyceo-dark-text-color);
  --ion-item-background: var(--lyceo-white-background);
  --ion-toolbar-color-activated: rgba(var(--lyceo-dark-blue-rgb), 0.78);
  --ion-toolbar-color-checked: rgba(var(--lyceo-dark-blue-rgb), 0.78);
  --ion-item-color: var(--lyceo-dark-text-color);
  --ion-item-border-color: var(--lyceo-dark-blue-70);
  --ion-placeholder-color: var(--lyceo-dark-blue-70);

  --ion-background-color-step-500: var(--lyceo-dark-blue-95);
}
