ion-skeleton-text {
  animation: 1.5s ease-in-out 0.5s infinite normal none running pulse;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
