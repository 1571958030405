ion-card {
  border-radius: 10px;
  margin: 0;
  box-shadow: none;

  &.elevation {
    box-shadow:
      rgb(0 0 0 / 0.2) 0 3px 1px -2px,
      rgb(0 0 0 / 0.14) 0px 2px 2px 0px,
      rgb(0 0 0 / 0.12) 0px 1px 5px 0px;
  }

  ion-card-header {
    flex-direction: column;
  }

  ion-card-title {
    padding: 16px 0;
  }

  ion-card-content {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
