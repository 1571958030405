td[mat-cell] ion-item.item-input,
th[mat-header-cell] ion-item.item-input {
  --min-height: 0;
  --padding-start: 8px;
  --padding-end: 8px;
  --inner-padding-end: 0;
  --tt-ion-input-width: auto;

  ion-input {
    width: var(--tt-ion-input-width, auto);
  }

  &.min-content {
    width: min-content;

    ion-input {
      text-align: center;
    }
  }
}
