table[mat-table] {
  width: 100%;

  tr[mat-header-row],
  tr[mat-row] {
    height: fit-content;
  }

  th[mat-header-cell] {
    background: rgba(var(--ion-color-base-rgb), 0.05);
    border-top: 1px solid rgba(var(--lyceo-dark-blue-rgb), 0.12);
    font-weight: var(--font-weight-medium);
  }

  td[mat-cell],
  th[mat-header-cell] {
    font-size: 0.875rem;
    color: var(--ion-text-color);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-color: rgba(var(--lyceo-dark-blue-rgb), 0.12);
    padding-right: 16px;

    &:first-child {
      padding-left: 16px;
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }
}

td[mat-cell] ion-item.item-input,
th[mat-header-cell] ion-item.item-input {
  --min-height: 0;
  --padding-start: 8px;
  --padding-end: 8px;
  --inner-padding-end: 0;
  --tt-ion-input-width: auto;

  ion-input {
    width: var(--tt-ion-input-width, auto);
  }

  &.min-content {
    width: min-content;

    ion-input {
      text-align: center;
    }
  }
}
