@use 'typography-mixin' as typo;

@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: url('../fonts/rubik-normal.woff2') format('woff2');
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: url('../fonts/rubik-medium.woff2') format('woff2');
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  src: url('../fonts/rubik-bold.woff2') format('woff2');
}

:root {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  color: var(--ion-text-color);
}

* {
  font-family: Rubik, sans-serif;
  --font-family: 'Rubik', sans-serif;
}

.tt-headline-1,
ion-label.tt-headline-1,
ion-card-title.tt-headline-1,
ion-card-subtitle.tt-headline-1 {
  @include typo.typography(typo.tt-headline-1());
}

.tt-headline-2,
ion-label.tt-headline-2,
ion-card-title.tt-headline-2,
ion-card-subtitle.tt-headline-2 {
  @include typo.typography(typo.tt-headline-2());
}

.tt-headline-3,
ion-label.tt-headline-3,
ion-card-title.tt-headline-3,
ion-card-subtitle.tt-headline-3 {
  @include typo.typography(typo.tt-headline-3());
}

.tt-headline-4,
ion-label.tt-headline-4,
ion-card-title.tt-headline-4,
ion-card-subtitle.tt-headline-4 {
  @include typo.typography(typo.tt-headline-4());
}

.tt-headline-5,
ion-label.tt-headline-5,
ion-card-title.tt-headline-5,
ion-card-subtitle.tt-headline-5 {
  @include typo.typography(typo.tt-headline-5());
}

.tt-headline-6,
ion-label.tt-headline-6,
ion-card-title.tt-headline-6,
ion-card-subtitle.tt-headline-6 {
  @include typo.typography(typo.tt-headline-6());
}

.tt-subtitle-1,
ion-label.tt-subtitle-1,
ion-card-title.tt-subtitle-1,
ion-card-subtitle.tt-subtitle-1 {
  @include typo.typography(typo.tt-subtitle-1());
}

.tt-subtitle-2,
ion-label.tt-subtitle-2,
ion-card-title.tt-subtitle-2,
ion-card-subtitle.tt-subtitle-2 {
  @include typo.typography(typo.tt-subtitle-2());
}

.tt-body-1,
ion-label.tt-body-1,
ion-card-title.tt-body-1,
ion-card-subtitle.tt-body-1 {
  @include typo.typography(typo.tt-body-1());
}

.tt-body,
.tt-body-2 {
  @include typo.typography(typo.tt-body-2());
}

.tt-caption {
  @include typo.typography(typo.tt-caption());
}

.tt-overline {
  @include typo.typography(typo.tt-overline());
}

ion-list-header {
  @include typo.typography(typo.tt-subtitle-2());
}

ion-button {
  @include typo.typography(typo.tt-text-button());
}

ion-item,
tt-menu,
ion-segment-button,
ion-label,
ion-textarea,
p {
  @include typo.typography(typo.tt-body-2());
}

ion-segment-button {
  text-transform: none;
}
