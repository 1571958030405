/**
 * Mimic the styling a ion-icon has when being in a slot
 *
 * Note cannot use ::slotted here because FontAwesome library broke any support for
 * shadow dom
 */
@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
  fa-icon[slot='start'] {
    margin-left: unset;
    margin-right: unset;
    margin-inline-end: 8px;
  }

  ::slotted([slot='start']) {
    margin-right: unset;
    margin-inline-end: 8px;
  }
}

fa-icon[slot='start'] {
  font-size: var(--tt-icon-size, 1.5rem);
  height: auto;
  pointer-events: none;

  & svg,
  & svg.svg-inline--fa.fa-fw {
    width: var(--tt-icon-size, var(--fa-fw-width, 24px));
  }
}

fa-icon[slot] {
  margin-top: 12px;
  margin-bottom: 12px;
}
