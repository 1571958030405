.sat-popover-container .popover {
  margin: 8px;
  padding: 8px;
  background: white;
  text-align: left;
  color: var(--lyceo-dark-text-color);
  border: solid 1px var(--lyceo-dark-blue-95);
  border-radius: 2px;
  font-size: 0.75rem;

  .separator {
    border-bottom: 1px solid white;
    height: 8px;
  }
}
