ion-modal {
  /**
   * add the class stack-modal to your modal to create a stacked modal.
   */

  &.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }

  &::part(content) {
    --border-radius: var(--tt-ion-modal-border-radius);
  }

  ion-content {
    &::part(scroll) {
      height: 100%;
    }
  }
}

.modal-xs::part(content) {
  --border-radius: 20px;
  --height: 480px;
  --width: 440px;

  @media (max-width: 575px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }
}

.modal-sm::part(content) {
  --border-radius: 20px;
  --height: 70vh;
  --width: 600px;

  @media (max-width: 767px) {
    --width: 95vw;
  }

  @media (max-width: 575px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }
}

.modal-md::part(content) {
  --height: 75vh;
  --width: 1150px;

  @media (max-width: 1199px) {
    --width: 95vw;
  }

  @media (max-width: 767px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }
}

.modal-lg::part(content) {
  --height: 85vh;
  --width: 85vw;

  @media (max-width: 1366px) {
    --width: 95vw;
  }

  @media (max-width: 767px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }
}

.modal-xl::part(content) {
  --height: 95vh;
  --width: 95vw;

  @media (max-width: 767px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }
}
