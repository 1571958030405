@use 'sass:string';

mat-tooltip-component {
  --mdc-plain-tooltip-supporting-text-weight: var(--font-weight-regular);
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-letter-spacing: 0.025rem;
  --mdc-plain-tooltip-supporting-text-family: string.unquote('Rubik, sans-serif');
  --mdc-plain-tooltip-supporting-text-color: var(--lyceo-dark-blue-contrast);
  --mdc-plain-tooltip-container-color: rgba(var(--lyceo-dark-blue-rgb), 0.8);

  .mdc-tooltip__surface {
    line-height: 1.5;
  }
}
