@use '@techniek-team/lyceo-style' as typo;

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@forward 'theme/material/mat-theme';

/* Core CSS required for Ionic components to work properly */
@forward '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@forward '@ionic/angular/css/normalize.css';
@forward '@ionic/angular/css/structure.css';
@forward '@ionic/angular/css/typography.css';
@forward '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@forward '@ionic/angular/css/padding.css';
@forward '@ionic/angular/css/float-elements.css';
@forward '@ionic/angular/css/text-alignment.css';
@forward '@ionic/angular/css/text-transformation.css';
@forward '@ionic/angular/css/flex-utils.css';

@forward '@techniek-team/lyceo-style/src/scss/ionic-lyceo-theme';

/* Custom style */
@forward 'theme/ionic/ion-card';
@forward 'theme/ionic/ion-content';
@forward 'theme/material/mat-form-field';
@forward 'theme/shared-library/highlight-search-pipe';
@forward 'theme/shared-library/statistic-card';
@forward 'theme/sat-popover';

::view-transition-old(count),
::view-transition-new(count) {
  animation-duration: 100ms;
}

:root {
  --lyceo-grey-background-2: #f6f6f6;
  //--lyceo-grey-background-2: #FFFCFC;
  --lyceo-grey-background-rgb-2: 255, 252, 252;
  --lyceo-grey-background: var(--lyceo-grey-background-2);
  --lyceo-grey-background-rgb: var(--lyceo-grey-background-rgb-2);

  color: var(--ion-text-color);
  --ion-background-color: var(--lyceo-grey-background);
  --ion-background-color-rgb: var(--lyceo-grey-background-rgb);
}

/** -----should be moved to style package------- **/
ion-select {
  --placeholder-opacity: 0.5;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

tt-modal {
  position: relative;
}

ion-modal {
  --width: fit-content;
}

button {
  background: none;
}

/** ------------ **/

// todo everything down here should not be in the global styling either because it's
//  dirty old or because it's should be held in a component instead of here.
.router-outlet-full-height + * {
  flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  max-height: 100%;
}

.cdk-overlay-pane {
  //stylelint-disable-next-line declaration-no-important
  max-width: 95vw !important;
}

blockquote {
  margin: 20px 0;
  padding-left: 15px;
  border-left: 5px solid var(--lyceo-green);
}

.empty-list-message {
  color: rgba(var(--ion-text-color-rgb), 0.12);
  padding: 16px;
}

.badge {
  margin: 0 6px;
  padding: 6px;
  text-align: center;
  background: var(--ion-color-medium-tint);
  color: var(--ion-text-color);
  box-sizing: border-box;
  white-space: nowrap;
  word-break: keep-all;
}

.scheduling-context-toolbar-icon-button {
  align-items: center;
  cursor: pointer;
  display: flex;

  i.material-icons {
    font-size: 36px;
  }
}

.blur {
  filter: blur(2px);
}

.heavy-blur {
  filter: blur(10px);
}

.banaan {
  cursor:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🍌</text></svg>")
      16 0,
    auto;
}

ion-modal.tt-simple-modal-unassign {
  --max-width: 500px;
}

.as-split-gutter {
  --_as-gutter-background-color: transparent;

  .shade-gutter {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .shade-gutter-icon {
      height: 48px;
      width: 4px;
      background: #79747e;
      border-radius: 10px;
      transition: all 0.3s;

      &:hover {
        width: 8px;
      }
    }

    &.no-width {
      margin-right: -16px;
      width: 4px;
    }
  }
}

.left-shadow {
  margin-left: 5px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.right-shadow {
  margin-right: 5px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.right-shadow-inset {
  box-shadow: inset 0 4px 15px 0 rgba(0, 0, 0, 0.1);

  & > ion-content {
    --background: transparent;
  }
}
