@use 'sass:string';
@use 'sass:map';

@function tt-headline-1() {
  @return (
    font-size: 6.125rem,
    line-height: 1.5,
    font-weight: var(--font-weight-light),
    letter-spacing: -0.09375rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-headline-2() {
  @return (
    font-weight: var(--font-weight-light),
    font-size: 3.8125rem,
    line-height: 1.5,
    letter-spacing: -0.03125rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-headline-3() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 2.5rem,
    line-height: 1.5,
    letter-spacing: normal,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-headline-4() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 2.1875rem,
    line-height: 1.5,
    letter-spacing: 0.015625rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-headline-5() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 1.5rem,
    line-height: 1.5,
    letter-spacing: normal,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-headline-6() {
  @return (
    font-weight: var(--font-weight-medium),
    font-size: 1.25rem,
    line-height: 1.5,
    letter-spacing: 0.009375rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-subtitle-1() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 1rem,
    line-height: 1.5,
    letter-spacing: 0.009375rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-subtitle-2() {
  @return (
    font-weight: var(--font-weight-medium),
    font-size: 0.875rem,
    line-height: 1.5,
    letter-spacing: 0.00625rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-body-1() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 1rem,
    line-height: 1.5,
    letter-spacing: 0.00625rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-body-2() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 0.8rem,
    //font-size: 0.875rem,
    line-height: 1.5,
    letter-spacing: 0.015625rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-caption() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 0.75rem,
    line-height: 1.5,
    letter-spacing: 0.025rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-overline() {
  @return (
    font-weight: var(--font-weight-regular),
    font-size: 0.625rem,
    line-height: 1.5,
    letter-spacing: 0.09375rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@function tt-text-button() {
  @return (
    font-weight: var(--font-weight-medium),
    font-size: 0.875rem,
    line-height: 1.5,
    letter-spacing: 0.007142857rem,
    font-family: string.unquote('Rubik, sans-serif')
  );
}

@mixin typography($typography) {
  font-weight: map.get($typography, 'font-weight');
  font-size: map.get($typography, 'font-size');
  line-height: map.get($typography, 'line-height');
  letter-spacing: map.get($typography, 'letter-spacing');
}
