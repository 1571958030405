:root {
  --lyceo-color-fr: #fcd45e;
  --lyceo-color-fr-rgb: 252, 212, 94;
  --lyceo-color-nl: #f18b66;
  --lyceo-color-nl-rgb: 241, 139, 102;
  --lyceo-color-en: #dd7874;
  --lyceo-color-en-rgb: 221, 120, 116;
  --lyceo-color-ec: #9cd4d6;
  --lyceo-color-ec-rgb: 156, 212, 214;
  --lyceo-color-sk: #cbabcb;
  --lyceo-color-sk-rgb: 203, 171, 203;
  --lyceo-color-ak: #f9b259;
  --lyceo-color-ak-rgb: 249, 178, 89;
  --lyceo-color-be: #74bfbd;
  --lyceo-color-be-rgb: 116, 191, 189;
  --lyceo-color-bi: #abd087;
  --lyceo-color-bi-rgb: 171, 208, 135;
  --lyceo-color-de: #bdd8ef;
  --lyceo-color-de-rgb: 189, 216, 239;
  --lyceo-color-du: #bdd8ef;
  --lyceo-color-du-rgb: 189, 216, 239;
  --lyceo-color-gs: #b6665f;
  --lyceo-color-gs-rgb: 182, 102, 95;
  --lyceo-color-gr: #d09193;
  --lyceo-color-gr-rgb: 208, 145, 147;
  --lyceo-color-la: #e77b94;
  --lyceo-color-la-rgb: 231, 123, 148;
  --lyceo-color-nask1: #418080;
  --lyceo-color-nask1-rgb: 65, 128, 128;
  --lyceo-color-nask2: #a092c6;
  --lyceo-color-nask2-rgb: 160, 146, 198;
  --lyceo-color-na: #96c1a8;
  --lyceo-color-na-rgb: 150, 193, 168;
  --lyceo-color-wi: #cce2cb;
  --lyceo-color-wi-rgb: 204, 226, 203;
  --lyceo-color-wa: #7fb4dc;
  --lyceo-color-wa-rgb: 127, 180, 220;
  --lyceo-color-wb: #768cc6;
  --lyceo-color-wb-rgb: 118, 140, 198;
  --lyceo-color-wc: #d6e29b;
  --lyceo-color-wc-rgb: 214, 226, 155;

  --lyceo-color-onderwijshelden_surveillant: #d8cde4;
  --lyceo-color-surveillant: var(--lyceo-color-onderwijshelden_surveillant);
  --lyceo-color-bijles_begeleider: #51baff;
  --lyceo-color-bijles: var(--lyceo-color-bijles_begeleider);
}
